import s from './Section4.module.css'
import lineChart from '../../../assets/images/График.svg'
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Импортируйте LazyLoadImage
import 'react-lazy-load-image-component/src/effects/blur.css';
export const Section4 = () => {
    const { t } = useTranslation('mains1');

    return (
        <section className={s.section4_block}>
            <div className={s.content}>
                <div className={s.header}>
                    <h2>{t('main-s4-h1')} <br /> <span>{t('alg')}</span></h2>
                    <p>{t('main-s4-h2')}</p>
                </div>
                <div className={s.line_block}>
                    <div className={s.lines}>
                        <div className={s.line}/>
                        <div className={s.circle}/>
                    </div>
                    <div className={s.buy_sell}>
                        <h3>Buy & Sell</h3>
                    </div>
                </div>

                <p className={s.text}>{t('main-s4-p')}</p>
            </div>
            <div className={s.line_chart}>
                <LazyLoadImage src={lineChart} alt="" wrapperClassName={s.line_chart_img} visibleByDefault={true} threshold="500"/>
                <div className={s.position}>
                    <div className={s.long}>
                        <span></span>
                        <p>{t('long')}</p>
                    </div>
                    <div className={s.short}>
                        <span></span>
                        <p>{t('short')}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}