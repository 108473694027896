import s from "./Section1.module.css";
import circles from "../../../assets/images/bg-shape.png";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Импортируйте LazyLoadImage
import 'react-lazy-load-image-component/src/effects/blur.css';
export const Section1 = () => {
  const { t } = useTranslation("mains1");

  return (

    <section className={s.section1_block}>
      <div className={s.content}>
        <div className={s.section1_text}>
          <h1>{t("main-s1-p")}</h1>
          <p>{t("main-s1-h1")}</p>
          <h2>{t("main-s1-h2")}</h2>
        </div>
        <div className={s.section1_btn}>
          <div className={s.lines}>
            <div className={s.line1} />
            <div className={s.line2} />
          </div>
          <p>{t("24/7")}</p>
          <a target="_blank" href="https://t.me/MetaHedge_bot" rel="noreferrer">
            <button>{t("request-btn")}</button>
          </a>
        </div>
      </div>
      <div className={s.shape_bg}>
          <LazyLoadImage src={circles} alt="background" effect="blur" />
      </div>
      <div className={s.glow} />
    </section>
  );
};
