import s from './Footer.module.css'
import logo from '../../assets/icons/Logo.svg'
import telega from '../../assets/icons/telega.svg'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Footer = () => {
    const { t } = useTranslation('footer');
    const [activeLink, setActiveLink] = useState(null);

    return (
        <div>
            <footer className={s.footer}>
                <div className={s.footer_logo}>

                    <LazyLoadImage src={logo} alt="logo" />
                    <div>{t('copyright')}</div>
                </div>
                <div className={s.footer_nav}>
                    <Link to="/" onClick={() => setActiveLink('/')} className={activeLink === '/' ? s.active_link : s.ne_active_link}>{t('nav_home')}</Link>
                    <Link to="/solutions" onClick={() => setActiveLink('/solutions')} className={activeLink === '/solutions' ? s.active_link : s.ne_active_link}>{t('nav1')}</Link>
                    <Link to="/instructions" onClick={() => setActiveLink('/instructions')} className={activeLink === '/instructions' ? s.active_link : s.ne_active_link}>{t('nav2')}</Link>
                    <a target='_blank' href="https://t.me/MetaHedge_bot" rel="noreferrer">
                        <LazyLoadImage src={telega} alt="telegram" />
                    </a>
                </div>
            </footer>
            <div className={s.glow} />
        </div>
    )
}