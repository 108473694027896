import { Bybit } from "../components/instructions/Bybit/Bybit"
import {Helmet} from "react-helmet";

export const Instructions = () => {
    return (
        <>
            <Helmet>
                <title>Инструкции по Запуску Торговых Роботов - MetaHedge</title>
                <meta name="description" content="Шаг за шагом руководства по началу работы с MetaHedge: регистрация на ByBit и RoboForex, верификация, активация МТ4 и подключение торговых роботов. Получите полную поддержку и настройте ваш торговый робот с MetaHedge." />
                <meta name="keywords" content="инструкции MetaHedge, руководство по регистрации ByBit, начать торговлю на RoboForex, активация MT4 терминал, верификация на криптовалютной бирже, подключение торгового робота, настройка торгового счета, депозит USDT, торговые стратегии, автоматизированная торговля криптовалютами" />
            </Helmet>
        <main className="container">
            <Bybit/>
        </main>
        </>
    )
}