
import { Section1 } from "../components/solutions/Section1/Section1"
import { Section2 } from "../components/solutions/Section2/Section2"
import { Helmet } from 'react-helmet';


export const Solution = () => {
    return (
        <>
        <Helmet>
            <title>Решения для Максимизации Прибыли - Meta Hedge Solutions</title>
            <meta name="description" content="Откройте решения Meta Hedge для максимизации прибыли: стратегии для высокой и низкой волатильности счетов, персонализированные подходы и эксклюзивные условия на платформе RoboForex. Инвестируйте умно с нашими инновационными решениями." />
            <meta name="keywords" content="стратегии максимизации прибыли, высокая доходность инвестиций, управление волатильностью счета, персонализированные инвестиционные решения, доинвестирование на просадке, RoboForex инвестиции, платформа Bybit, инвестиционные услуги, управление инвестиционным портфелем, пассивный доход стратегии" />
        </Helmet>
   <main className="container container-redact">
            <Section1/>
            <Section2/>
        </main>
        </>
    )
}