import { Section1 } from './components/partials/Section1/Section1';
import { Section10 } from './components/partials/Section10/Section10';
import { Section2 } from './components/partials/Section2/Section2';
import { Section3 } from './components/partials/Section3/Section3';
import { Section4 } from './components/partials/Section4/Section4';
import { Section5 } from './components/partials/Section5/Section5';
import { Section6 } from './components/partials/Section6/Section6';
import { Section7 } from './components/partials/Section7/Section7';
import { Section8 } from './components/partials/Section8/Section8';
import { Section9 } from './components/partials/Section9/Section9';
import { Helmet } from 'react-helmet';
const App = () => {
  return (
    <main>
        <Helmet>
            <title>Meta Hedge - Автоматизированные Торговые Роботы для Форекс и Пассивного Дохода</title>
            <meta name="description" content="Meta Hedge предлагает передовые решения в области автоматической торговли для инвесторов, желающих получать пассивный доход на форексе. Откройте для себя лучшие торговые роботы, стратегии инвестирования и секреты успешной автоматической торговли." />
            <meta name="keywords" content="автоматизированные торговые системы форекс, лучшие торговые роботы для инвестирования, пассивный доход через автоматическую торговлю, инвестиционные стратегии с использованием роботов, программное обеспечение для автоматической торговли, отзывы о торговых роботах форекс, руководство по настройке торгового робота, виды пассивного дохода на финансовых рынках, секреты успешной автоматической торговли" />
        </Helmet>
        <div className="container">
        <div className="dot_grid" />
        <Section1 />
        <Section2 />
      </div>
      <div className="container">
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
      </div>
    </main>
  );
};

export default App;
