import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Для эффекта размытия при загрузке

import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import dot_grid from '../assets/images/grid-dot.png';

const Layout = ({ children }) => {
    return (
        <div>
            <Header />
            {/* Используйте LazyLoadImage вместо обычного img */}
            <LazyLoadImage
                alt=""
                src={dot_grid}
                effect="blur"
                className='dot_grid'
            />
            <main>
                {children}
            </main>
            <div className='footer_line' />
            <div className='container'>
                <Footer />
            </div>
        </div>
    );
};

export default Layout;
