import React, { useEffect, useState } from 'react';
import s from './LoginModal.module.css';
import { useTranslation } from 'react-i18next';

const LoginModal = ({ showModal, handleClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation('login', { useSuspense: false });

  const clickOutside = (e) => {
    if (e.target.className === s.modalTransparentBackground) {
      handleClose();
    }
  }

  useEffect(() => {
    setIsModalOpen(showModal);
  }, [showModal]);

  return (
    <>
      <div className={s.modalTransparentBackground} onClick={clickOutside} style={{
        visibility: isModalOpen ? 'visible' : 'hidden',
      }}>
        <div className={s.modal}>
          <div className={s.modalHeader}>
            <h2>{t('title')}</h2>
            <button className={s.modalClose} onClick={handleClose}>X</button>
          </div>
          <div className={s.modalBody}>
            <form onSubmit={(e) => {
              e.preventDefault();
              if (!!e.target.username.value && !!e.target.password.value) {
                setErrorMessage(t('user_error_not_found'));
              }
            }}>
              <input type="text" id="username" name="username" placeholder={t('name_placeholder')} />

              <input type="password" id="password" name="password" placeholder={t('password_placeholder')} />

              {errorMessage && <div className={s.errorMessage}>{errorMessage}</div>}

              <div className={s.submit}>
                <button type="submit">{t('title')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;